import { fetchFacility } from "../api";
import { PointLayer } from "@antv/l7";
export default {

    data() {
        return {
            cutoffwellDetail: {},
            cutoffwellVisible: false,
        }
    },

    watch: {
        cutoffwellVisible() {
            if (!this.cutoffwellVisible) {
                this.closeFacilityActive();
            }
        }
    },

    methods: {
        getcutoffwell() {

            if (!this.cutoffwellLayer) {

                fetchFacility({
                }).then((res) => {

                    console.log('res', res)

                    if (Array.isArray(res)) {

                        const list = Object.freeze(res);

                        const zoom = this.map?.getZoom() || 3;

                        this.cutoffwellLayer = new PointLayer({
                            zIndex: 100,
                        })

                            .source(list.filter(item => item.type === 'cutoffWell'), {
                                parser: {
                                    type: 'json',
                                    x: 'x',
                                    y: 'y'
                                }
                            })
                            .shape('cutoffwell')
                            .size(zoom * 0.55)

                        this.cutoffwellLayer.on('click', this.onCutoffWellClick)


                        this.scene?.addLayer(this.cutoffwellLayer);

                    }
                });



            } else {
                this.cutoffwellLayer.show();
            }

        },

        onCutoffWellClick(e) {
            console.log('e', e)
            this.cutoffwellDetail = e.feature;
            this.setFacilityActive(e.feature, 'cutoffwellVisible')
        },

        removecutoffwell() {
            this.cutoffwellVisible = false;
            this.cutoffwellLayer?.hide();

        },


                    setFacilityActive(info, key, change) {

                        this.closeFacilityActive();

                 const zoom = this.map?.getZoom() || 3;

                this.facilityActiveLayer = new PointLayer({
                    zIndex: 100,
                })
                    .source([info], {
                        parser: {
                            type: 'json',
                            x: 'x',
                            y: 'y'
                        }
                    })
                    .shape('simple')
                    .color('#ff0000')
                    .style({
                          strokeWidth: 3
                    })
                    .size(zoom * 0.6)

                const that = this;
                this.facilityActiveLayer.on('click', function () {
                        that.closeFacilityActive();
                that[key] = false;
                })


                this.scene?.addLayer(this.facilityActiveLayer);

                this[key] = true

                if (change) {
                        this.scene?.setCenter([info.x, info.y], {
                    //   padding: {
                    //     right: 400,
                    //   },
                  });

                }


        },

               closeFacilityActive() {
            if (this.facilityActiveLayer) {
                this.scene?.removeLayer(this.facilityActiveLayer);
                this.facilityActiveLayer = null;
            }
        }



    }
}

