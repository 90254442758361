<template>
  <div>
    <div id="map"></div>

    <intercepting-well-detail
      :detail="cutoffwellDetail"
      v-if="cutoffwellVisible"
      @changeVisible="(payload) => (cutoffwellVisible = payload)"
    />

    <div class="card">
      <div class="header">
        <div class="title">截流井列表</div>
        <div class="extra" @click="toggleView">
          <a-icon type="fullscreen" v-if="view === 'list'" />
          <a-icon type="fullscreen-exit" v-else />
        </div>
      </div>
      <div class="body">
        <List
          :list="list"
          v-if="view === 'list'"
          @detail="detail"
          @focus="focus"
        />
        <Cards :list="cardList" v-else @detail="detail" @focus="focus" />
      </div>
    </div>
  </div>
</template>

<script>
import { Scene, Marker, MarkerLayer } from "@antv/l7";
import { GaodeMapV2 } from "@antv/l7-maps";
import cutoffwell from "./mixins/cutoffwell";
import InterceptingWellDetail from "./components/intercepting-well-detail.vue";
import List from "./components/list.vue";
import Cards from "./components/cards.vue";

import request from "@/api/request";

function fetchList() {
  return request({
    url: "/model-analysis/pipe/cutoffwell/query/alert",
    method: "get",
  });
}

function fetchData(data) {
  return request({
    url: "/data-service/dev/realTime/batch",
    method: "post",
    data,
  });
}

export default {
  components: {
    InterceptingWellDetail,
    List,
    Cards,
  },
  mixins: [cutoffwell],

  data() {
    return {
      view: "list",
      list: [],
      cardList: [],
    };
  },

  mounted() {
    this.initMap();

    this.scene = new Scene({
      id: "map",
      logoVisible: false,
      map: new GaodeMapV2({
        mapInstance: this.map,
      }),
    });

    this.scene?.addImage(
      "cutoffwell",
      require("@/assets/gis/left-tool/cutoffwell.png")
    );
    // this.getcutoffwell();
    this.getList();
  },

  beforeDestroy() {
    console.log(" beforeDestroy");
    this.scene?.destroy();
  },

  methods: {
    initMap() {
      // 先加载底图，调用高德插件
      const { AMap } = window;
      if (!AMap) {
        return;
      }
      var map = new AMap.Map("map", {
        center: [119.056708, 33.583976],
        zoom: 15,
        mapStyle: "amap://styles/52faa27d18984923cceae498330eceb1",

        WebGLParams: {
          preserveDrawingBuffer: true,
        },
      });

      // AMap.plugin(["AMap.Scale", "AMap.HawkEye"], function () {
      //   map.addControl(new AMap.Scale());
      //   map.addControl(new AMap.HawkEye({ isOpen: true }));
      // });

      this.map = map;

      return map;
    },

    getList() {
      fetchList().then((res) => {
        if (Array.isArray(res)) {
          this.list = Object.freeze(res);

          [
            {
              devType: "intel_cutoffWell_qirun_main",
              eids: ["urpftf", "gq9mm5"],
            },
            {
              devType: "intel_cutoffWell_shengyu_other",
              eids: ["vdaqjt", "hdvc5n"],
            },
          ];

          const arr = [];

          res.forEach((item) => {
            if (!item.sn) {
              console.log("no sn", item);
              return;
            }
            const index = arr.findIndex(
              (element) => element.devType === item.subType
            );
            if (index === -1) {
              arr.push({
                devType: item.subType,
                eids: [item.sn],
              });
            } else {
              arr[index].eids.push(item.sn);
            }
          });
          this.getData(arr);
          this.setMarker();
        }
      });
    },

    getData(arr) {
      fetchData(arr).then((res) => {
        console.log("alert", res);
        if (res && typeof res === "object") {
          const cardList = [];
          Object.keys(res).forEach((key) => {
            if (Array.isArray(res[key])) {
              res[key].forEach((item) => {
                const info =
                  this.list.find((element) => element.sn === item.sn) || {};
                cardList.push({
                  ...info,
                  ...item,
                });
              });
            }
          });
          this.cardList = Object.freeze(cardList);
        }
      });
    },

    setMarker() {
      const markerLayer = new MarkerLayer({});

      const markers = [];

      this.list.forEach((item) => {
        var el = document.createElement("div");
        el.innerHTML = `
      <div style="background-color: #000;border-radius: 100px;border: 2px solid #00c037;line-height: 32px;font-size: 14px;display: flex;align-items: center; padding: 0 8px ">
          <img src='/icon/intercepting-well-active.png' style="height: 18px;margin-right: 8px"  />
          <span>${item.name}</span>
      </div>
    `;

        const marker = new Marker({
          element: el,
          extData: item,
        }).setLnglat({
          lng: item.x,
          lat: item.y,
        });

        marker.on("click", this.onMarkerClick);

        markers.push({
          ...item,
          marker,
        });

        markerLayer.addMarker(marker);
      });

      this.scene?.addMarkerLayer(markerLayer);

      this.markers = markers;
    },

    onMarkerClick(e) {
      console.log("e", e);
      // this.setFacilityActive(e.data, 'cutoffwellVisible')
      this.cutoffwellDetail = e.data;
      this.cutoffwellVisible = true;

      this.setMarkerActive();
    },

    setMarkerActive() {
      this.markers?.forEach((item) => {
        var el = document.createElement("div");

        if (item.id === this.cutoffwellDetail.id) {
          el.innerHTML = `
      <div style="background-color: #000;border-radius: 100px;border: 2px solid #ff0000;line-height: 32px;font-size: 14px;display: flex;align-items: center; padding: 0 8px ">
          <div style="margin-right: 8px;border: 2px solid #ff0000;border-radius: 50%">
                  <img src='/icon/intercepting-well-active.png' style="height: 18px;display:block"  />
          </div>
          <span style='color: #ff0000'>${item.name}</span>
      </div>
    `;
        } else {
          el.innerHTML = `
      <div style="background-color: #000;border-radius: 100px;border: 2px solid #00c037;line-height: 32px;font-size: 14px;display: flex;align-items: center; padding: 0 8px ">
          <img src='/icon/intercepting-well-active.png' style="height: 18px;margin-right: 8px"  />
          <span>${item.name}</span>
      </div>
    `;
        }

        item.marker.setElement(el);
      });
    },

    toggleView() {
      if (this.view === "list") {
        this.view = "card";
      } else {
        this.view = "list";
      }
    },

    detail(item) {
      this.cutoffwellDetail = item;
      this.cutoffwellVisible = true;
      this.setMarkerActive();
    },
    focus(item) {
      this.cutoffwellDetail = item;
      this.setMarkerActive();
    },
  },
};
</script>

<style lang="less" scoped>
#map {
  position: fixed;
  top: 10vh;
  left: 4vw;
  right: 4vw;
  height: 86vh;
}

.card {
  position: fixed;
  top: 10vh;
  left: 4vw;
  z-index: 99;
  bottom: 4vh;
  overflow-y: auto;
  background-color: #000;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);

  & > .header {
    background-color: var(--theme-color);
    cursor: pointer;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 998;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1vw;
    line-height: 4vh;
  }

  .body {
    padding: 2vh 1vw;
  }
}
</style>