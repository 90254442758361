<template>
  <div class="list">
    <div class="item" v-for="item in list " :key="item.id">
      <div class="header">
        <div class="title">{{item.name}}</div>
        <div class="extra">编号：{{item.code}}</div>
      </div>
      <div class="info">
        <span class="label">位置：</span>
        <span>{{item.location}}</span>
        <span style="margin-left: auto;cursor: pointer" @click="$emit('detail', item)">
          <img src="@/assets/topic/info.png" style="width: 18px" alt />
        </span>
      </div>
      <div class="info">
        <span class="label">设备状态：</span>
        <span
          v-if="Array.isArray(item.alert) && item.alert > 0"
          style="color: red"
        >{{ item.alert.join('，')}}</span>
        <span v-else style="color: var(--theme-color)">正常</span>
        <span style="margin-left: auto;cursor: pointer" @click="$emit('focus', item)">
          <img src="@/assets/topic/location.png" style="width: 18px" alt />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  display: flex;
  flex-direction: column;
  gap: 1em;
  .item {
    border: 1px solid #f0f0f0;
    padding: 1em;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-weight: bold;
        font-size: 1.1em;
      }
      .extra {
        color: #999;
        font-size: 0.9em;
      }
    }
    .info {
      margin-top: 0.5em;
      color: #999;
      display: flex;
      align-items: center;
    }
  }
}
</style>