<template>
  <div class="list">
    <div class="item" v-for="item in list " :key="item.id">
      <div class="header">
        <div class="title">{{item.name}}</div>
        <div class="extra">编号：{{item.code}}</div>
      </div>
      <div class="info">
        <span class="label">更新时间：</span>
        <span>{{item.ts}}</span>
      </div>

      <div class="content" v-if="item.subType === 'intel_cutoffWell_shengyu_yeya'">
        <div class="info">
          <div class="label">井内液位：</div>
          <div
            class="value"
          >{{ item.liquidLevelInner !== undefined ? item.liquidLevelInner + "mm" : "--" }}</div>
        </div>
        <div class="info">
          <div class="label">井外液位：</div>
          <div class="value">
            {{ item.liquidLevelOuter !== undefined ?
            (item.liquidLevelOuter > 0 ? item.liquidLevelOuter : 0) + "mm" : "--" }}
          </div>
        </div>
        <div class="info">
          <div class="label">污水液位：</div>
          <div class="value">
            {{ item.liquidLevelDirtyPipe !== undefined ?
            (item.liquidLevelDirtyPipe > 0 ? item.liquidLevelDirtyPipe : 0) + "mm" : "--" }}
          </div>
        </div>

        <div class="info">
          <div class="label">SS：</div>
          <div class="value">{{ item.ss !== undefined ? item.ss + 'mg/l' : "--" }}</div>
        </div>

        <div class="info">
          <div class="label">外部采集SS：</div>
          <div class="value">{{ item.claatekSs !== undefined ? item.claatekSs + 'mg/l' : "--" }}</div>
        </div>

        <div class="info">
          <div class="label">雨量：</div>
          <div
            class="value"
          >{{ item.precipitation !== undefined ? item.precipitation + 'dmm' : "--" }}</div>
        </div>

        <!-- <div class="info">
        <div class="label">1#气囊压力(kpa)</div>
        <div
          class="value"
        >{{ item.pressureAirbag1 !== undefined ? item.pressureAirbag1 : "--" }}</div>
      </div>

      <div class="info">
        <div class="label">2#气囊压力(kpa)</div>
        <div
          class="value"
        >{{ item.pressureAirbag2 !== undefined ? item.pressureAirbag2 : "--" }}</div>
      </div>
      <div class="info">
        <div class="label">1#闸门状态</div>
        <div class="value">
          {{ item.isIntakeValveOpenAirbag1
          !== undefined ? (item.isIntakeValveOpenAirbag1 === 0 ? '关' : '开') : "--" }}
        </div>
      </div>

      <div class="info">
        <div class="label">2#闸门状态</div>
        <div class="value">
          {{ item.isIntakeValveOpenAirbag2
          !== undefined ? (item.isIntakeValveOpenAirbag2 === 0 ? '关' : '开') : "--" }}
        </div>
        </div>-->

        <div class="info">
          <div class="label">工作状态：</div>
          <div class="value">
            {{ item.workStatus
            !== undefined ? item.workStatus
            : "--" }}
          </div>
        </div>

        <div class="info">
          <div class="label">运行模式：</div>
          <div class="value">
            {{ item.isAutoMode
            !== undefined ? (item.isAutoMode == 1 ? '自动' : '手动')
            : "--" }}
          </div>
        </div>

        <div class="info">
          <div class="label">设备状态：</div>
          <div class="value">
            <span
              v-if="Array.isArray(item.alert) && item.alert > 0"
              style="color: red"
            >{{ item.alert.join('，')}}</span>
            <span v-else style="color: var(--theme-color)">正常</span>
          </div>
        </div>
      </div>
      <!--Other类型的圣禹截流井-->
      <div class="content" v-if="item.subType === 'intel_cutoffWell_shengyu_other'">
        <div class="info">
          <div class="label">井内液位：</div>
          <div
            class="value"
          >{{ item.liquidLevelInner !== undefined ? item.liquidLevelInner + "mm" : "--" }}</div>
        </div>
        <div class="info">
          <div class="label">井外液位：</div>
          <div class="value">
            {{ item.liquidLevelOuter !== undefined ?
            (item.liquidLevelOuter > 0 ? item.liquidLevelOuter : 0) + "mm" : "--" }}
          </div>
        </div>
        <div class="info">
          <div class="label">污水液位：</div>
          <div class="value">
            {{ item.liquidLevelDirtyPipe !== undefined ?
            (item.liquidLevelDirtyPipe > 0 ? item.liquidLevelDirtyPipe : 0) + "mm" : "--" }}
          </div>
        </div>

        <div class="info">
          <div class="label">SS：</div>
          <div class="value">{{ item.ss !== undefined ? item.ss + 'mg/l' : "--" }}</div>
        </div>

        <div class="info">
          <div class="label">外部采集SS：</div>
          <div class="value">{{ item.claatekSs !== undefined ? item.claatekSs + 'mg/l': "--" }}</div>
        </div>
        <div class="info">
          <div class="label">雨量：</div>
          <div
            class="value"
          >{{ item.precipitation !== undefined ? item.precipitation + 'dmm' : "--" }}</div>
        </div>

        <div class="info">
          <div class="label">工作状态：</div>
          <div class="value">
            {{ item.workStatus
            !== undefined ? item.workStatus
            : "--" }}
          </div>
        </div>

        <div class="info">
          <div class="label">运行模式：</div>
          <div class="value">
            <span v-if="String(item.workMode) === '0'">自动</span>
            <span v-if="String(item.workMode) === '1'">手动</span>
            <span v-if="String(item.workMode) === '2'">远程手动</span>
          </div>
        </div>

        <div class="info">
          <div class="label">设备状态：</div>
          <div class="value">
            <span
              v-if="Array.isArray(item.alert) && item.alert > 0"
              style="color: red"
            >{{ item.alert.join('，')}}</span>
            <span v-else style="color: var(--theme-color)">正常</span>
          </div>
        </div>
      </div>

      <!--main类型的祺润截流井-->
      <div class="content" v-if="item.subType === 'intel_cutoffWell_qirun_main'">
        <div class="info">
          <div class="label">液位：</div>
          <div class="value">{{ item.liquidLevel !== undefined ? item.liquidLevel + "m" : "--" }}</div>
        </div>

        <div class="info">
          <div class="label">外部采集SS：</div>
          <div class="value">{{ item.claatekSs !== undefined ? item.claatekSs + 'mg/l' : "--" }}</div>
        </div>
        <div class="info">
          <div class="label">雨量：</div>
          <div class="value">{{ item.rainfall !== undefined ? item.rainfall + 'dmm' : "--" }}</div>
        </div>

        <div class="info">
          <div class="label">油压：</div>
          <div
            class="value"
          >{{ item.gateOilPressure !== undefined ? item.gateOilPressure + 'kpa' : "--" }}</div>
        </div>
        <div class="info">
          <div class="label">截留闸开度：</div>
          <div
            class="value"
          >{{ item.cutoffGateOpening !== undefined ? item.cutoffGateOpening : "--" }}</div>
        </div>

        <div class="info">
          <div class="label">出水闸开度：</div>
          <div
            class="value"
          >{{ item.outputGateOpening !== undefined ? item.outputGateOpening : "--" }}</div>
        </div>
        <div class="info">
          <div class="label">cod：</div>
          <div class="value">{{ item.cod !== undefined ? item.cod : "--" }}</div>
        </div>

        <div class="info">
          <div class="label">油温报警状态位：</div>
          <div class="value">
            {{ item.isOilTemperatureWarn
            ? '异常'
            : "正常" }}
          </div>
        </div>

        <div class="info">
          <div class="label">油泵液位报警状态位：</div>
          <div class="value">
            {{ item.isOilLiquidLevelWarn
            ? '正常'
            : "异常" }}
          </div>
        </div>

        <div class="info">
          <div class="label">运行模式：</div>
          <div class="value">
            {{ item.isLocalMode
            === true ? '本地模式' : (item.isAutoMode === true ? '自动模式' : 'unknown')
            }}
          </div>
        </div>

        <div class="info">
          <div class="label">设备状态：</div>
          <div class="value">
            <span
              v-if="Array.isArray(item.alert) && item.alert > 0"
              style="color: red"
            >{{ item.alert.join('，')}}</span>
            <span v-else style="color: var(--theme-color)">正常</span>
          </div>
        </div>
      </div>

      <div class="info footer">
        <span v-if="item.subType === 'intel_cutoffWell_qirun_main'" class="label">闸门：</span>
        <span v-else class="label">气囊：</span>

        <span v-if="item.subType === 'intel_cutoffWell_qirun_main'" style="margin-right: 1em">
          <img
            v-if="item.cutoffGateOpening === 0"
            src="@/assets/topic/gate-close.png"
            style="width: 24px;margin-right: 0.5em"
            alt
          />
          <img
            v-else-if="item.cutoffGateOpening < 100"
            src="@/assets/topic/gate-half.png"
            style="width: 24px;margin-right: 0.5em"
            alt
          />
          <img
            v-else-if="item.cutoffGateOpening >= 100"
            src="@/assets/topic/gate-open.png"
            style="width: 24px;margin-right: 0.5em"
            alt
          />

          <img
            v-if="item.outputGateOpening === 0"
            src="@/assets/topic/gate-close.png"
            style="width: 24px;margin-right: 0.5em"
            alt
          />
          <img
            v-else-if="item.outputGateOpening < 100"
            src="@/assets/topic/gate-half.png"
            style="width: 24px;margin-right: 0.5em"
            alt
          />
          <img
            v-else-if="item.outputGateOpening >= 100"
            src="@/assets/topic/gate-open.png"
            style="width: 24px;margin-right: 0.5em"
            alt
          />
        </span>
        <span v-if="item.subType === 'intel_cutoffWell_shengyu_yeya'" style="margin-right: 1em">
          <img
            v-if="item.gateOpeningAirbag1 === 0"
            src="@/assets/topic/gate-close.png"
            style="width: 24px;margin-right: 0.5em"
            alt
          />
          <img
            v-else-if="item.gateOpeningAirbag1 < 100"
            src="@/assets/topic/gate-half.png"
            style="width: 24px;margin-right: 0.5em"
            alt
          />
          <img
            v-else-if="item.gateOpeningAirbag1 >= 100"
            src="@/assets/topic/gate-open.png"
            style="width: 24px;margin-right: 0.5em"
            alt
          />

          <img
            v-if="item.gateOpeningAirbag2 === 0"
            src="@/assets/topic/gate-close.png"
            style="width: 24px;margin-right: 0.5em"
            alt
          />
          <img
            v-else-if="item.gateOpeningAirbag2 < 100"
            src="@/assets/topic/gate-half.png"
            style="width: 24px;margin-right: 0.5em"
            alt
          />
          <img
            v-else-if="item.gateOpeningAirbag2 >= 100"
            src="@/assets/topic/gate-open.png"
            style="width: 24px;margin-right: 0.5em"
            alt
          />
        </span>

        <span v-if="item.subType === 'intel_cutoffWell_shengyu_other'" style="margin-right: 1em">
          <img
            v-if="item.gateOpeningAirbag1 === 0"
            src="@/assets/topic/gate-close.png"
            style="width: 24px;margin-right: 0.5em"
            alt
          />
          <img
            v-else-if="item.gateOpeningAirbag1 < 100"
            src="@/assets/topic/gate-half.png"
            style="width: 24px;margin-right: 0.5em"
            alt
          />
          <img
            v-else-if="item.gateOpeningAirbag1 >= 100"
            src="@/assets/topic/gate-open.png"
            style="width: 24px;margin-right: 0.5em"
            alt
          />

          <img
            v-if="item.gateOpeningAirbag2 === 0"
            src="@/assets/topic/gate-close.png"
            style="width: 24px;margin-right: 0.5em"
            alt
          />
          <img
            v-else-if="item.gateOpeningAirbag2 < 100"
            src="@/assets/topic/gate-half.png"
            style="width: 24px;margin-right: 0.5em"
            alt
          />
          <img
            v-else-if="item.gateOpeningAirbag2 >= 100"
            src="@/assets/topic/gate-open.png"
            style="width: 24px;margin-right: 0.5em"
            alt
          />
        </span>

        <span style="margin-left: auto;cursor: pointer" @click="$emit('detail', item)">
          <img src="@/assets/topic/info.png" style="width: 18px" alt />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1em;
  .item {
    border: 1px solid #f0f0f0;
    padding: 1em;
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-weight: bold;
        font-size: 1.1em;
        white-space: nowrap;
      }
      .extra {
        color: #999;
        font-size: 0.9em;
        white-space: nowrap;
        margin-left: 1em;
      }
    }
    .info {
      margin-top: 0.5em;
      color: #999;
      display: flex;
      align-items: center;
    }

    .content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 0.5em;
    }

    .footer {
      padding-top: 0.5em;
      margin-top: auto;
    }
  }
}
</style>