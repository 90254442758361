<template>
  <div class="container">
    <div class="header" @click="$emit('changeVisible')">
      <div class="title">{{ this.detail.name }}</div>
      <div class="extra">
        <a-icon type="close" />
      </div>
    </div>
    <div class="card-content">
      <!--基本信息-->
      <div class="base-panel">
        <h1>数据</h1>
        <!--数据展示-->
        <div>
          <FbxLoader
            v-if="fbx_refresh"
            class="model"
            url="/well.fbx"
            type="well"
            :width="420"
            :height="300"
            :text="fbx_labels"
          />
        </div>
        <!--fenliu类型的圣禹截流井（已移除）-->

        <!--yeya类型的圣禹截流井-->
        <div
          class="content"
          v-if="detail.subType === 'intel_cutoffWell_shengyu_yeya'"
        >
          <div class="item">
            <div class="label">更新时间</div>
            <div class="value">
              {{ realTimeData.ts !== undefined ? realTimeData.ts : "--" }}
              <!-- 时间戳 -->
            </div>
          </div>
          <div class="item">
            <div class="label">井内液位(mm)</div>
            <div class="value">
              {{
                realTimeData.liquidLevelInner !== undefined
                  ? realTimeData.liquidLevelInner + "mm"
                  : "--"
              }}
              <!-- liquidLevelInner -->
            </div>
          </div>
          <div class="item">
            <div class="label">井外液位(mm)</div>
            <div class="value">
              {{
                realTimeData.liquidLevelOuter !== undefined
                  ? (realTimeData.liquidLevelOuter > 0
                      ? realTimeData.liquidLevelOuter
                      : 0) + "mm"
                  : "--"
              }}
              <!-- liquidLevelOuter -->
            </div>
          </div>
          <div class="item">
            <div class="label">污水液位(mm)</div>
            <div class="value">
              {{
                realTimeData.liquidLevelDirtyPipe !== undefined
                  ? (realTimeData.liquidLevelDirtyPipe > 0
                      ? realTimeData.liquidLevelDirtyPipe
                      : 0) + "mm"
                  : "--"
              }}
              <!-- liquidLevelDirtyPipe -->
            </div>
          </div>

          <div class="item">
            <div class="label">SS(mg/l)</div>
            <div class="value">
              {{ realTimeData.ss !== undefined ? realTimeData.ss : "--" }}
              <!-- ss -->
            </div>
          </div>
          <div class="item">
            <div class="label">雨量(dmm)</div>
            <div class="value">
              {{
                realTimeData.precipitation !== undefined
                  ? realTimeData.precipitation
                  : "--"
              }}
              <!-- precipitation -->
            </div>
          </div>

          <div class="item">
            <div class="label">1#气囊压力(kpa)</div>
            <div class="value">
              {{
                realTimeData.pressureAirbag1 !== undefined
                  ? realTimeData.pressureAirbag1
                  : "--"
              }}
              <!-- pressureAirbag1 -->
            </div>
          </div>

          <div class="item">
            <div class="label">2#气囊压力(kpa)</div>
            <div class="value">
              {{
                realTimeData.pressureAirbag2 !== undefined
                  ? realTimeData.pressureAirbag2
                  : "--"
              }}
              <!-- pressureAirbag1 -->
            </div>
          </div>
          <div class="item">
            <div class="label">1#闸门状态</div>
            <div class="value">
              {{
                realTimeData.isIntakeValveOpenAirbag1 !== undefined
                  ? realTimeData.isIntakeValveOpenAirbag1 === 0
                    ? "关"
                    : "开"
                  : "--"
              }}
              <!-- gateOpeningAirbag1 -->
            </div>
          </div>

          <div class="item">
            <div class="label">2#闸门状态</div>
            <div class="value">
              {{
                realTimeData.isIntakeValveOpenAirbag2 !== undefined
                  ? realTimeData.isIntakeValveOpenAirbag2 === 0
                    ? "关"
                    : "开"
                  : "--"
              }}
              <!-- gateOpeningAirbag1 -->
            </div>
          </div>

          <div class="item">
            <div class="label">工作状态</div>
            <div class="value">
              {{
                realTimeData.workStatus !== undefined
                  ? realTimeData.workStatus
                  : "--"
              }}
              <!-- gateOpeningAirbag2 -->
            </div>
          </div>

          <div class="item">
            <div class="label">运行模式</div>
            <div class="value">
              {{
                realTimeData.isAutoMode !== undefined
                  ? realTimeData.isAutoMode == 1
                    ? "自动"
                    : "手动"
                  : "--"
              }}
              <!-- gateOpeningAirbag2 -->
            </div>
          </div>
          <div class="item">
            <div class="label">外部采集SS(mg/l)</div>
            <div class="value">
              {{
                realTimeData.claatekSs !== undefined
                  ? realTimeData.claatekSs
                  : "--"
              }}
            </div>
          </div>
        </div>
        <!--Other类型的圣禹截流井-->
        <div
          class="content"
          v-if="detail.subType === 'intel_cutoffWell_shengyu_other'"
        >
          <div class="item">
            <div class="label">更新时间</div>
            <div class="value">
              {{ realTimeData.ts !== undefined ? realTimeData.ts : "--" }}
              <!-- 时间戳 -->
            </div>
          </div>
          <div class="item">
            <div class="label">井内液位(mm)</div>
            <div class="value">
              {{
                realTimeData.liquidLevelInner !== undefined
                  ? realTimeData.liquidLevelInner + "mm"
                  : "--"
              }}
              <!-- liquidLevelInner -->
            </div>
          </div>
          <div class="item">
            <div class="label">井外液位(mm)</div>
            <div class="value">
              {{
                realTimeData.liquidLevelOuter !== undefined
                  ? (realTimeData.liquidLevelOuter > 0
                      ? realTimeData.liquidLevelOuter
                      : 0) + "mm"
                  : "--"
              }}
              <!-- liquidLevelOuter -->
            </div>
          </div>
          <div class="item">
            <div class="label">污水液位(mm)</div>
            <div class="value">
              {{
                realTimeData.liquidLevelDirtyPipe !== undefined
                  ? (realTimeData.liquidLevelDirtyPipe > 0
                      ? realTimeData.liquidLevelDirtyPipe
                      : 0) + "mm"
                  : "--"
              }}
              <!-- liquidLevelDirtyPipe -->
            </div>
          </div>

          <div class="item">
            <div class="label">SS(mg/l)</div>
            <div class="value">
              {{ realTimeData.ss !== undefined ? realTimeData.ss : "--" }}
              <!-- ss -->
            </div>
          </div>
          <div class="item">
            <div class="label">雨量(dmm)</div>
            <div class="value">
              {{
                realTimeData.precipitation !== undefined
                  ? realTimeData.precipitation
                  : "--"
              }}
              <!-- precipitation -->
            </div>
          </div>

          <div class="item">
            <div class="label">1#气囊压力(kpa)</div>
            <div class="value">
              {{
                realTimeData.pressureAirbag1 !== undefined
                  ? realTimeData.pressureAirbag1
                  : "--"
              }}
              <!-- pressureAirbag1 -->
            </div>
          </div>
          <div class="item">
            <div class="label">1#气囊开度(%)</div>
            <div class="value">
              {{
                realTimeData.gateOpeningAirbag1 !== undefined
                  ? realTimeData.gateOpeningAirbag1
                  : "--"
              }}
              <!-- gateOpeningAirbag1 -->
            </div>
          </div>

          <div class="item">
            <div class="label">2#气囊压力(kpa)</div>
            <div class="value">
              {{
                realTimeData.pressureAirbag2 !== undefined
                  ? realTimeData.pressureAirbag2
                  : "--"
              }}
              <!-- pressureAirbag2 -->
            </div>
          </div>
          <div class="item">
            <div class="label">2#气囊开度(%)</div>
            <div class="value">
              {{
                realTimeData.gateOpeningAirbag2 !== undefined
                  ? realTimeData.gateOpeningAirbag2
                  : "--"
              }}
              <!-- gateOpeningAirbag2 -->
            </div>
          </div>

          <div class="item">
            <div class="label">工作状态</div>
            <div class="value">
              {{
                realTimeData.workStatus !== undefined
                  ? realTimeData.workStatus
                  : "--"
              }}
              <!-- gateOpeningAirbag2 -->
            </div>
          </div>

          <div class="item">
            <div class="label">运行模式</div>
            <div class="value">
              {{
                realTimeData.workMode !== undefined
                  ? shengyu_cutoff_well_other_dict.workMode[
                      realTimeData.workMode.toString()
                    ]
                  : "--"
              }}
              <!-- gateOpeningAirbag2 -->
            </div>
          </div>
          <div class="item">
            <div class="label">外部采集SS(mg/l)</div>
            <div class="value">
              {{
                realTimeData.claatekSs !== undefined
                  ? realTimeData.claatekSs
                  : "--"
              }}
            </div>
          </div>
        </div>

        <!--main类型的祺润截流井-->
        <div
          class="content"
          v-if="detail.subType === 'intel_cutoffWell_qirun_main'"
        >
          <div class="item">
            <div class="label">数据更新时间</div>
            <div class="value">
              {{ realTimeData.ts ? realTimeData.ts : "--" }}
            </div>
          </div>
          <div class="item">
            <div class="label">液位(m)</div>
            <div class="value">
              {{
                realTimeData.liquidLevel !== undefined
                  ? realTimeData.liquidLevel + "m"
                  : "--"
              }}
            </div>
          </div>

          <div class="item">
            <div class="label">外部采集SS(mg/l)</div>
            <div class="value">
              {{
                realTimeData.claatekSs !== undefined
                  ? realTimeData.claatekSs
                  : "--"
              }}
            </div>
          </div>
          <div class="item">
            <div class="label">雨量(dmm)</div>
            <div class="value">
              {{
                realTimeData.rainfall !== undefined
                  ? realTimeData.rainfall
                  : "--"
              }}
            </div>
          </div>

          <div class="item">
            <div class="label">油压(kpa)</div>
            <div class="value">
              {{
                realTimeData.gateOilPressure !== undefined
                  ? realTimeData.gateOilPressure
                  : "--"
              }}
            </div>
          </div>
          <div class="item">
            <div class="label">截留闸开度</div>
            <div class="value">
              {{
                realTimeData.cutoffGateOpening !== undefined
                  ? realTimeData.cutoffGateOpening
                  : "--"
              }}
            </div>
          </div>

          <div class="item">
            <div class="label">出水闸开度</div>
            <div class="value">
              {{
                realTimeData.outputGateOpening !== undefined
                  ? realTimeData.outputGateOpening
                  : "--"
              }}
            </div>
          </div>
          <div class="item">
            <div class="label">cod</div>
            <div class="value">
              {{ realTimeData.cod !== undefined ? realTimeData.cod : "--" }}
            </div>
          </div>

          <div class="item">
            <div class="label">油温报警状态位</div>
            <div class="value">
              {{ realTimeData.isOilTemperatureWarn ? "异常" : "正常" }}
            </div>
          </div>

          <div class="item">
            <div class="label">油泵液位报警状态位</div>
            <div class="value">
              {{
                realTimeData.isOilLiquidLevelWarn !== undefined
                  ? realTimeData.isOilLiquidLevelWarn
                  : "--"
              }}
            </div>
          </div>

          <div class="item">
            <div class="label">运行模式</div>
            <div class="value">
              {{
                realTimeData.isLocalMode === true
                  ? "本地模式"
                  : realTimeData.isAutoMode === true
                  ? "自动模式"
                  : "unknown"
              }}
            </div>
          </div>
        </div>
        <!-- 发送控制命令部分未对接
        <div>
          <a-button type="primary">开启闸门</a-button>
          <a-button>关闭闸门</a-button>
          <a-button type="primary">启动远控</a-button>
          <a-button>关闭远控</a-button>
        </div>
        -->
      </div>
      <!--视频监控-->
      <div class="monitor-panel">
        <h1>监控</h1>
        <video src="#" id="video" controls autoplay ref="video"></video>
        <div>
          <div v-if="ipc_name == null" class="item">
            <div class="value">视频未接入</div>
          </div>
          <div v-else class="ptz_panel">
            <div class="left-panel">
              <a-button @click="play_video">播放</a-button>
              <a-button @click="stop_video">中止</a-button>
              <a-button @click="start_control(ipc_name)">控制云台</a-button>
              <a-button @click="stop_control(ipc_name)">结束控制</a-button>
            </div>
            <div class="right-panel">
              <MonitorControlPanel :ipc_name="ipc_name" />
            </div>
          </div>
        </div>
      </div>
      <!--统计数据-->
      <div class="statistic-panel">
        <h1>统计</h1>
        <a-space style="margin-bottom: 1em; padding: 0 1em">
          <a-range-picker
            size="small"
            style="width: 12vw"
            v-model="selectedDate"
          />
          <a-select
            placeholder="间隔"
            size="small"
            style="width: 4vw"
            v-model="interval"
          >
            <a-select-option v-for="item in statInterval" :key="item.value">
              {{ item.name }}
            </a-select-option>
          </a-select>
          <a-button type="primary" size="small" @click="query">查询</a-button>
          <a-button size="small" @click="reset">重置</a-button>
        </a-space>

        <div>
          <a-select
            size="small"
            placeholder="指标"
            style="width: 6vw"
            v-model="chart_A_queryOption"
          >
            <a-select-option v-for="item in queryOptions" :key="item.value">
              {{ item.name }}
            </a-select-option>
          </a-select>
          <intercepting-well-chart :list="ChartAList" :color="'blue'" />
        </div>

        <div>
          <a-select
            size="small"
            placeholder="指标"
            style="width: 6vw"
            v-model="chart_B_queryOption"
          >
            <a-select-option v-for="item in queryOptions" :key="item.value">
              {{ item.name }}
            </a-select-option>
          </a-select>
          <intercepting-well-chart :list="ChartBList" :color="'green'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { fetchFacilityDetail } from "../api";
import InterceptingWellChart from "./intercepting-well-chart.vue";
import FbxLoader from "@/components/fbx-loader.vue";
import request from "@/api/request";
import { mapGetters } from "vuex";
import moment from "moment";
import { get_cutoff_ipcname_and_url } from "@/api/ipc_media/index.js";
import { start_control, stop_control } from "@/api/ipc_controller/index.js";
import MonitorControlPanel from "@/components/monitor-control-panel.vue";

export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    InterceptingWellChart,
    FbxLoader,
    MonitorControlPanel,
  },

  data() {
    return {
      fbx_refresh: true,
      activeTab: "2",
      info: {},
      loading: false,
      chartList: [],
      realTimeData: {},
      selectedDate: [moment().subtract(1, "months"), moment()],

      chart_A_queryOption: "liquidLevelInner",
      chart_B_queryOption: "liquidLevelOuter",
      interval: "1h",
      fbx_labels: [
        {
          text: "管底标高：128.52m",
          position: [0, 2, 60],
        },
        {
          text: "井外液位：0.2m",
          position: [0, 5, 0],
        },
        {
          text: "井内液位：0.2m",
          position: [0, -25, 60],
        },
        {
          text: "井底标高：128.37m",
          position: [0, -40, 0],
        },
        {
          text: "闸门A开关：100%",
          position: [-20, -10, 30],
        },
        {
          text: "闸门B开关：100%",
          position: [20, -10, 0],
        },
      ],
      // 摄像头相关
      ipc_name: null,
      webrtc_url: null,
      player: null,

      //

      shengyu_cutoff_well_other_dict: {
        workMode: {
          0: "自动",
          1: "手动",
          2: "远程手动",
        },
      },
    };
  },
  watch: {
    detail() {
      this.getRealTimeData();
      this.getHistoryData();

      this.stop_video();
      this.get_ipc_info();
    },
  },

  mounted() {
    this.getRealTimeData();
    this.getHistoryData();

    this.get_ipc_info();
    if (this.detail.subType === "intel_cutoffWell_qirun_main") {
      this.chart_A_queryOption = "liquidLevel";
      this.chart_B_queryOption = "claatekSs";
    }
    // 设置60秒的周期获取realtimedata
    this.getRealTimeData_interval = setInterval(() => {
      this.getRealTimeData();
    }, 60 * 1000); //
  },
  beforeDestroy() {
    console.log("destroyed");
    this.stop_video();

    if (this.getRealTimeData_interval) {
      clearInterval(this.getRealTimeData_interval);
      console.log("clear getRealTimeData_interval");
    }
  },

  methods: {
    getRealTimeData() {
      /** 获取水质监测箱实时的数据 */
      let that = this;
      request({
        url: "/data-service/dev/realTime",
        method: "post",
        data: {
          devType: this.detail.subType, // 获取类型
          eids: [this.detail.sn],
        },
      })
        .then((res) => {
          this.realTimeData = res;
        })
        .then(() => {
          that.fbx_refresh = false;
          that.$nextTick(function () {
            that.updateFbxLabels();
            that.fbx_refresh = true;
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getHistoryData() {
      // TODO 历史界面请求报错
      this.loading = true;

      if (this.selectedDate.length !== 2) return;
      let that = this;
      request({
        url: "/data-service/dev/stat",
        method: "post",
        data: {
          devType: this.detail.subType,
          beginDate: this.selectedDate[0].format("YYYY-MM-DD") + " 00:00:00",
          endDate: this.selectedDate[1].format("YYYY-MM-DD") + " 00:00:00",
          eids: [this.detail.sn],
          interval: that.interval,
        },
      }).then((res) => {
        if (Array.isArray(res)) {
          this.chartList = res;
        }
      });
    },
    query() {
      this.getHistoryData();
    },
    reset() {
      this.selectedDate = [moment().subtract(1, "months"), moment()];
      this.interval = "1h";
      this.getHistoryData();
    },
    updateFbxLabels() {
      this.fbx_labels[1].text = `井外液位：${this.realTimeData.liquidLevelOuter}mm`;
      this.fbx_labels[2].text = `井内液位：${this.realTimeData.liquidLevelInner}mm`;

      this.fbx_labels[4].text = `闸门A开关：${this.GateStatus_A}`;
      this.fbx_labels[5].text = `闸门B开关：${this.GateStatus_B}`;
    },
    // --- 摄像头相关
    async get_ipc_info() {
      //this.detail.
      let res = await get_cutoff_ipcname_and_url(this.detail.id);
      console.log(res);
      /**获取IPC信息 */
      if (res.videoPropVo === undefined) {
        this.ipc_name = null;
        this.webrtc_url = null;
        return;
      } else {
        this.ipc_name = res.videoPropVo.ipcName;
        this.webrtc_url = `http://223.75.200.168:18080/index/api/webrtc?app=${res.videoPropVo.appName}&stream=${res.videoPropVo.streamName}&type=play`;
        this.play_video();
      }
    },
    start_control() {
      if (this.ipc_name !== undefined) {
        start_control(this.ipc_name);
      }
    },
    stop_control() {
      if (this.ipc_name !== undefined) {
        stop_control(this.ipc_name);
      }
    },
    play_video() {
      this.stop_video();
      console.log("playing");

      const url = this.webrtc_url;
      const video_element = this.$refs.video;
      let cfg = {
        element: video_element,
        debug: false,
        simulcast: false,
        zlmsdpUrl: url,
        resolution: { w: 1920, h: 1080 },
        videoEnable: true,
        audioEnable: false,
        useCamera: false,
        usedatachannel: false,
        recvOnly: true,
      };
      this.player = new window.ZLMRTCClient.Endpoint(cfg);
    },
    stop_video() {
      if (this.player != null) {
        console.log("stop playing video");
        this.player.close();
        this.player = null;
        var remote = this.$refs.video;
        if (remote) {
          remote.srcObject = null;
          remote.load();
        }
      }
    },
  },
  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    queryOptions() {
      let shengyu_yeya_queryOptions = [
        {
          name: "井内液位",
          value: "liquidLevelInner",
        },
        {
          name: "井外液位",
          value: "liquidLevelOuter",
        },
        {
          name: "污水液位",
          value: "liquidLevelDirtyPipe",
        },
        {
          name: "SS",
          value: "ss",
        },
        {
          name: "雨量",
          value: "precipitation",
        },
        {
          name: "1#气囊压力(kpa)",
          value: "pressureAirbag1",
        },
        {
          name: "2#气囊压力(kpa)",
          value: "pressureAirbag2",
        },
        {
          name: "1#闸门状态",
          value: "isIntakeValveOpenAirbag1",
        },
        {
          name: "2#闸门状态",
          value: "isIntakeValveOpenAirbag2",
        },
        {
          name: "外部SS",
          value: "claatekSs",
        },
      ];

      let shengyu_other_queryOptions = [
        {
          name: "井内液位",
          value: "liquidLevelInner",
        },
        {
          name: "井外液位",
          value: "liquidLevelOuter",
        },
        {
          name: "污水液位",
          value: "liquidLevelDirtyPipe",
        },
        {
          name: "SS",
          value: "ss",
        },
        {
          name: "雨量",
          value: "precipitation",
        },
        {
          name: "1#气囊压力(kpa)",
          value: "pressureAirbag1",
        },
        {
          name: "2#气囊压力(kpa)",
          value: "pressureAirbag2",
        },
        {
          name: "1#气囊开度",
          value: "gateOpeningAirbag1",
        },
        {
          name: "2#气囊开度",
          value: "gateOpeningAirbag2",
        },
        {
          name: "外部SS",
          value: "claatekSs",
        },
      ];

      let qirun_queryOptions = [
        {
          name: "液位",
          value: "liquidLevel",
        },
        {
          name: "雨量",
          value: "rainfall",
        },
        {
          name: "COD",
          value: "cod",
        },
        {
          name: "油压",
          value: "gateOilPressure",
        },
        {
          name: "截留闸开度",
          value: "cutoffGateOpening",
        },
        {
          name: "出水闸开度",
          value: "outputGateOpening",
        },
        {
          name: "外部SS",
          value: "claatekSs",
        },
      ];

      if (this.detail.subType === "intel_cutoffWell_qirun_main") {
        return qirun_queryOptions;
      } else {
        // 不是祺润就返回圣禹
        if (this.detail.subType === "intel_cutoffWell_shengyu_yeya") {
          return shengyu_yeya_queryOptions;
        }
        return shengyu_other_queryOptions;
      }
    },
    statInterval() {
      return this.findDataDict("statInterval");
    },
    GateStatus_A() {
      /** 排水门A开关状态 */
      if (this.detail.subType === "intel_cutoffWell_shengyu_other") {
        // donothin
        return this.realTimeData.gateOpeningAirbag1 > 50 ? 1 : 0;
      } else if (this.detail.subType === "intel_cutoffWell_shengyu_yeya") {
        // donothin
        return this.realTimeData.isIntakeValveOpenAirbag1;
      } else if (this.detail.subType === "intel_cutoffWell_shengyu_fenliu") {
        // donothin
        return this.realTimeData.gateOpening1 > 50 ? 1 : 0;
      } else if (this.detail.subType === "intel_cutoffWell_qirun_main") {
        return this.realTimeData.cutoffGateOpening > 50 ? 1 : 0;
      }
      return 0;
    },
    GateStatus_B() {
      /** 排水门B开关状态 */
      if (this.detail.subType === "intel_cutoffWell_shengyu_other") {
        // donothin
        return this.realTimeData.gateOpeningAirbag2 > 50 ? 1 : 0;
      } else if (this.detail.subType === "intel_cutoffWell_shengyu_yeya") {
        return this.realTimeData.isIntakeValveOpenAirbag2;
        // donothin
      } else if (this.detail.subType === "intel_cutoffWell_shengyu_fenliu") {
        // donothin
        return 0; // 分流井没有这个值
      } else if (this.detail.subType === "intel_cutoffWell_qirun_main") {
        return this.realTimeData.outputGateOpening > 50 ? 1 : 0;
      }

      return 0;
    },
    ChartAList() {
      if (this.chartList) {
        const select_historydata = this.chartList.map((item) => {
          const xname = this.chart_A_queryOption;

          return {
            ts: item.ts,
            value: item[xname],
          };
        });
        return select_historydata;
      }
      return [];
    },
    ChartBList() {
      if (this.chartList) {
        const select_historydata = this.chartList.map((item) => {
          const xname = this.chart_B_queryOption;

          return {
            ts: item.ts,
            value: item[xname],
          };
        });
        return select_historydata;
      }

      return [];
    },
  },
};
</script>


<style lang="less" scoped>
.container {
  position: fixed;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  width: 90vw;
  height: 80vh;
  max-width: 1720px;
  max-height: 1200px;
  z-index: 999;

  & > .header {
    background-color: var(--theme-color);
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1vw;
    line-height: 4vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .card-content {
    padding: 2vh 1vw;
    gap: 1vh 1em;

    background-color: var(--bg-color);
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
    border-radius: 10px;

    display: flex;
  }
}

.content {
  padding: 2vh 1vw;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1vh 1em;

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1vh;

    .label {
      min-width: 4em;
    }

    .value {
      color: var(--theme-color);
    }
  }

  .col-2 {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

.info-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1vh 1vw;

  & > div:first-child {
    font-weight: bold;
  }

  & > div:last-child {
    color: #999;
    font-size: 12px;
    display: flex;
    align-items: center;
  }

  .refresh {
    margin-left: 4px;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate 1s linear infinite;
}

#video {
  width: 100%;
}

.body {
  .model {
    margin: 0 auto;
  }

  .preview {
    padding: 2vh 1vw;
    position: relative;
    font-size: 12px;
    font-weight: bold;

    img {
      width: 100%;
    }

    .height1 {
      color: #555;
      position: absolute;
      bottom: 13vh;
      border-bottom: 2px solid #555;

      &::after {
        content: "";
        position: absolute;
        bottom: -24px;
        right: -0.2vw;
        border-top: 12px solid #555;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid transparent;
      }
    }

    .height2 {
      color: #555;
      position: absolute;
      bottom: 10vh;
      left: 11vw;
    }

    .height3 {
      color: #555;
      position: absolute;
      bottom: 6vh;
      right: 2vw;
      border-bottom: 2px solid #555;

      &::after {
        content: "";
        position: absolute;
        bottom: -24px;
        left: -0.2vw;
        border-top: 12px solid #555;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid transparent;
      }
    }

    .gate {
      color: #555;
      position: absolute;
      bottom: 15vh;
      right: 8vw;
    }
  }

  .ptz_panel {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 2rem;
    background-color: var(--themeColor);

    .left-panel {
      display: flex;
      flex-direction: column;
    }
  }
}

.base-panel {
  flex: 1;
  margin-right: 0.5rem;
}

.statistic-panel {
  flex: 1;
}

.monitor-panel {
  flex: 1;
  margin-right: 0.5rem;
}
</style>