<template>
  <div ref="inter_chart" style="height: 20vh; width: 100%"></div>
</template>

<script>
import * as echarts from "echarts/core";
import { TooltipComponent, GridComponent } from "echarts/components";
import { LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([GridComponent, TooltipComponent, LineChart, CanvasRenderer]);

export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    color: {
      type: String,
      default: "#0AD8F3",
    },
  },
  watch: {
    list() {
      this.renderChart();
    },
  },
  mounted() {
    var chartDom = this.$refs.inter_chart;
    this.chart = echarts.init(chartDom);
    this.renderChart();
  },

  beforeDestroy() {
    this.chart?.dispose();
  },
  methods: {
    renderChart() {
      // "#FFA700", "#0AD8F3"
      let option = {
        color: [this.color],
        tooltip: {
          trigger: "item",
        },
        grid: {
          top: "10%",
          left: "8%",
          right: "3%",
          bottom: "20%",
        },
        xAxis: {
          type: "category",
          splitLine: {
            show: true,
          },
          axisLabel: {
            color: "#999",
          },
          data: this.list.map((item) => item.ts),
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "值",
            type: "line",
            data: this.list.map((item) => item.value),
          },
        ],
      };

      option && this.chart.setOption(option);
    },
  },
};
</script>