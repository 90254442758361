import request from '@/api/request'


// 截流设施
export function fetchFacility() {
    return request({
        url: '/model-analysis/pipe/facility/all',
    })
}

